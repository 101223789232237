<template>
  <div>
    <course-final-test-add-new :final-tests.sync="tests" :test="test"
                               :is-add-edit-test-sidebar-active.sync="isAddEditFinalTestSidebarActive"
    />

    <course-module-add-new
        :is-add-edit-module-sidebar-active.sync="isAddEditModuleSidebarActive"
        :module="selectedModule"
        :modules.sync="modules"
    />
    <course-head-add-new
        :is-add-edit-head-sidebar-active.sync="isAddEditHeadSidebarActive"
        :module="selectedModule"
        :head="selectedHead"
        :modules.sync="modules"
    />
    <course-lesson-add-new
        :is-add-edit-lesson-sidebar-active.sync="isAddEditLessonSidebarActive"
        :module="selectedModule"
        :head="selectedHead"
        :lesson="selectedLesson"
        :modules.sync="modules"
    />
    <course-test-add-new
        :is-add-edit-test-sidebar-active.sync="isAddEditTestSidebarActive"
        :module="selectedModule"
        :head="selectedHead"
        :test="selectedTest"
        :modules.sync="modules"
    />
    <form-wizard
        color="#0B63AC"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Сохранить"
        back-button-text="Предыдущий"
        next-button-text="Следующий"
        class="mb-3"
        @on-complete="formSubmitted"
    >

      <!-- course details tab -->
      <tab-content
          title="Информация о курсах"
          :before-change="validationForm"
      >
        <validation-observer
            ref="courseDetailsRules"
            tag="form"
        >
          <b-row>
            <b-col
                cols="12"
                class="mb-2"
            >
              <h5 class="mb-0">
                Информация о курсах
              </h5>
              <small class="text-muted">
                Введите информацию о вашей курсе.
              </small>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label="Тип"
                  label-for="type"
                  label-class="mb-1"
              >
                <validation-provider
                    #default="{ errors }"
                    name="тип курса"
                    rules="required"
                >
                  <b-form-radio-group
                      v-model="type"
                      :options="typeOptions"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label="Наименование курса"
                  label-for="title"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Наименование курса"
                    rules="required"
                >
                  <b-form-input
                      id="email"
                      v-model="title"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                      placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label="Фото"
                  label-for="photo"
              >
                <b-form-file
                    id="photo"
                    v-model="photo"
                    browse-text="Обзор..."
                    accept="image/*"
                    placeholder="Выберите файл или перетащите его сюда..."
                    drop-placeholder="Скиньте файл сюда..."
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label="Описание"
                  label-for="about"
              >
                <quill-editor
                    id="about"
                    v-model="about"
                    :options="editorOption"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </validation-observer>
      </tab-content>

      <!-- module details tab -->
      <tab-content
          v-if="type===1"
          title="Информация о модулях"
          :before-change="validationFormModuleDetails"
      >
        <validation-observer
            ref="moduleDetailsRules"
            tag="form"
        >
          <b-row>
            <b-col
                cols="9"
                sm="12"
                md="9"
                class="mb-2"
            >
              <h5 class="mb-0">
                Информация о модулях
              </h5>
              <small class="text-muted">Введите информацию о вашей модуле.</small>
            </b-col>
            <b-col
                class="mb-2"
                cols="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
            >
              <b-button
                  variant="light"
                  class="w-100"
                  @click="showAddEditModuleSidebar(-1)"
              >
                <span class="text-nowrap">Добавить модуль</span>
              </b-button>
            </b-col>
            <b-col md="12">
              <app-collapse
                  accordion
                  :type="'border'"
              >
                <draggable
                    v-model="modules"
                    class="list-group list-group-flush cursor-move"
                    tag="div"
                >
                  <transition-group
                      type="transition"
                      name="flip-list"
                  >
                    <app-collapse-item
                        v-for="(listItem, index) in modules"
                        :key="index"
                        :title="listItem.name"
                        :id-item="index"
                        :delete-action="true"
                        :edit-action="true"
                        @edit-action-click="showAddEditModuleSidebar"
                        @delete-action-click="deleteModule"
                    >
                      <b-row class="justify-content-end">

                        <b-col
                            sm="12"
                            xl="3"
                            lg="4"
                            md="4"
                            class="mb-1"
                        >
                          <b-button
                              variant="light"
                              class="w-100"
                              @click="showAddEditHeadSidebar(index, -1)"
                          >
                            <span class="text-nowrap">Добавить раздел</span>
                          </b-button>
                        </b-col>
                        <b-col
                            sm="12"
                            xl="3"
                            lg="4"
                            md="4"
                            class="mb-1"
                        >
                          <b-button
                              variant="light"
                              class="w-100"
                              @click="callLessonOrTest(index, -1, -1, 'lesson')"
                          >
                            <span class="text-nowrap">Добавить тему</span>
                          </b-button>
                        </b-col>
                        <b-col
                            sm="12"
                            xl="3"
                            lg="4"
                            md="4"
                            class="mb-1"
                        >
                          <b-button
                              variant="light"
                              class="w-100"
                              @click="callLessonOrTest(index, -1, -1, 'test')"
                          >
                            <span class="text-nowrap">Добавить тест</span>
                          </b-button>
                        </b-col>
                      </b-row>
                      <app-collapse
                          v-if="listItem.child.length > 0"
                          accordion
                          :type="'border'"
                      >
                        <draggable
                            v-model="listItem.child"
                            class="list-group list-group-flush cursor-move"
                            tag="div"
                        >
                          <transition-group
                              type="transition"
                              name="flip-list"
                          >
                            <app-collapse-item
                                v-for="(listItemChildItem, indexChild) in listItem.child"
                                :key="indexChild"
                                :title="listItemChildItem.name"
                                :id-item="[index, indexChild]"
                                :delete-action="true"
                                :edit-action="true"
                                @edit-action-click="showAddEditHeadSidebar"
                                @delete-action-click="deleteHead"
                            >
                              <b-row
                                  v-if="listItemChildItem.type === 'head'"
                                  class="justify-content-end"
                              >
                                <b-col
                                    sm="12"
                                    xl="3"
                                    lg="4"
                                    md="4"
                                    class="mb-1"
                                >
                                  <b-button
                                      variant="light"
                                      class="w-100"
                                      @click="callLessonOrTest(index, indexChild, -1, 'lesson')"
                                  >
                                    <feather-icon
                                        icon="PlusIcon"
                                        class="mr-50"
                                        size="18"
                                    />
                                    <span class="text-nowrap">Добавить тему</span>
                                  </b-button>
                                </b-col>
                                <b-col
                                    sm="12"
                                    xl="3"
                                    lg="4"
                                    md="4"
                                    class="mb-1"
                                >
                                  <b-button
                                      variant="light"
                                      class="w-100"
                                      @click="callLessonOrTest(index, indexChild, -1, 'test')"
                                  >
                                    <span class="text-nowrap">Добавить тест</span>
                                  </b-button>
                                </b-col>
                              </b-row>
                              <b-row v-else-if="listItemChildItem.type === 'lesson'">
                                <b-col
                                    sm="12"
                                    xl="12"
                                    lg="12"
                                    md="12"
                                    class=""
                                    v-html="listItemChildItem.text"
                                />
                              </b-row>

                              <app-collapse
                                  v-if="listItemChildItem.child.length > 0"
                                  accordion
                                  :type="'border'"
                              >
                                <draggable
                                    v-model="listItemChildItem.child"
                                    class="list-group list-group-flush cursor-move"
                                    tag="div"
                                >
                                  <transition-group
                                      type="transition"
                                      name="flip-list"
                                  >
                                    <app-collapse-item
                                        v-for="(listItemChild2Item, indexChild2) in listItemChildItem.child"
                                        :key="indexChild2"
                                        :title="listItemChild2Item.name"
                                        :id-item="[index, indexChild, indexChild2]"
                                        :delete-action="true"
                                        :edit-action="true"
                                        @edit-action-click="showAddEditLessonSidebar"
                                        @delete-action-click="deleteLesson"
                                    />
                                  </transition-group>
                                </draggable>
                              </app-collapse>

                            </app-collapse-item>
                          </transition-group>
                        </draggable>
                      </app-collapse>
                    </app-collapse-item>
                  </transition-group>
                </draggable>
              </app-collapse>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- final test  -->
      <tab-content
          title="Финальные тесты"
          :before-change="validationFormFinalTest"
      >
        <validation-observer
            ref="finalTestRules"
            tag="form"
        >
          <b-row>
            <b-col
                cols="12"
                class="mb-2"
            >
              <h5 class="mb-0">
                Финальные тесты
              </h5>
              <small class="text-muted">Введите информацию о вашей финальной тест.</small>
            </b-col>

          </b-row>
          <b-row>
            <b-col
                cols="12"
                class=""
            >
              <b-form-group>
                <b-button
                    variant="light"
                    class="float-right"
                    @click="addTest(-1)"
                >
                  <span class="text-nowrap">Добавить вопрос</span>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class=""
            >
              <b-table responsive="sm" :items="tests" :fields="fields">
                <template #cell(text)="data">
                  <div v-html="data.item.text"></div>
                </template>
                <template #cell(options)="data">
                  <div v-html="data.item.options.length"></div>
                </template>
                <template #cell(action)="data">
                  <span class="d-none">{{ data.index + 1 }}</span>
                  <span @click="addTest(data.index)">
                    <feather-icon icon="EditIcon" class="text-primary mr-1 font-medium-5"/>
                  </span>
                  <span @click="deleteTest(data.index)">
                    <feather-icon icon="Trash2Icon" class="text-danger mr-1 font-medium-5"/>
                  </span>
                </template>
              </b-table>
            </b-col>
          </b-row>

        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BFormInvalidFeedback,
  BFormRadioGroup,
  BListGroupItem,
  BAvatar,
  BCardText,
  BMediaBody,
  BMedia,
  BMediaAside,
  BButton,
  BFormCheckbox,
  BFormFile,
  BTable,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
import * as Quill from 'quill'
import { ref } from '@vue/composition-api'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import draggable from 'vuedraggable'
import { required, email } from '@validations'
import ru from 'vee-validate/dist/locale/ru.json'
import CourseModuleAddNew from './CourseModuleAddNew.vue'
import CourseHeadAddNew from './CourseHeadAddNew.vue'
import CourseLessonAddNew from './CourseLessonAddNew.vue'
import CourseTestAddNew from './CourseTestAddNew.vue'
import CourseFinalTestAddNew from '@/views/dashboard/courses/courses-add/CourseFinalTestAddNew'

Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    CourseModuleAddNew,
    CourseHeadAddNew,
    CourseLessonAddNew,
    CourseTestAddNew,
    CourseFinalTestAddNew,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BFormRadioGroup,
    BListGroupItem,
    BAvatar,
    BCardText,
    BMediaBody,
    BMedia,
    BMediaAside,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormFile,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    quillEditor,
    ImageResize,
    draggable,
  },
  data() {
    return {
      fields: [
        {
          key: 'text',
          label: 'Вопрос'
        },
        {
          key: 'options',
          label: 'Кол-во вариантов'
        },
        {
          key: 'action',
          label: 'Действие'
        },
      ],
      collapseType: 'default',
      type: 1,
      title: '',
      photo: null,
      about: '',
      typeOptions: [
        {
          text: 'Курс',
          value: 1,
        },
        {
          text: 'Тест',
          value: 2,
        },
      ],
      selectedModule: -1,
      selectedHead: -1,
      selectedLesson: -1,
      selectedTest: -1,
      modules: [
        // {
        //   name: 'Модуль 1',
        //   child: [
        //     {
        //       type: 'lesson',
        //       name: 'Введение',
        //       typeText: 'textEditor',
        //       text: '',
        //       file: null,
        //       child: [],
        //     },
        //     {
        //       type: 'head',
        //       name: 'Глава 1',
        //       typeText: '',
        //       text: '',
        //       file: null,
        //       child: [
        //         {
        //           type: 'lesson',
        //           name: 'Урок 1',
        //           typeText: 'textEditor',
        //           text: '',
        //           file: null,
        //         },
        //         {
        //           type: 'lesson',
        //           name: 'Урок 2',
        //           typeText: 'pdf',
        //           text: '',
        //           file: null,
        //         },
        //         {
        //           type: 'test',
        //           name: 'Тест',
        //           tests: [
        //             {
        //               text: 'Саволи 1',
        //               options: [
        //                 {
        //                   text: 'Вариант А',
        //                   correct: true,
        //                 },
        //                 {
        //                   text: 'Вариант Б',
        //                   correct: false,
        //                 },
        //                 {
        //                   text: 'Вариант С',
        //                   correct: false,
        //                 },
        //                 {
        //                   text: 'Вариант Д',
        //                   correct: false,
        //                 },
        //               ],
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
        // {
        //   name: 'Модуль 2',
        //   child: [],
        // },
        // {
        //   name: 'Модуль 3',
        //   child: [],
        // },
        // {
        //   name: 'Модуль 4',
        //   child: [],
        // },
        // {
        //   name: 'Модуль 5',
        //   child: [],
        // },
        // {
        //   name: 'Модуль 6',
        //   child: [],
        // },
        // {
        //   name: 'Модуль 7',
        //   child: [],
        // },
        // {
        //   name: 'Модуль 8',
        //   child: [],
        // },
      ],
      tests: [],
      test: -1,
      required,
      email,
      editorOption: {
        theme: 'snow',
        bounds: '#scrolling-container',
        scrollingContainer: '#scrolling-container',
        placeholder: 'Описание',
        modules: {
          imageResize: {},
          toolbar: {
            container: [
              [{ size: ['6px', '8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ],
          },
        },
      },
    }
  },
  setup() {
    const isAddEditModuleSidebarActive = ref(false)
    const isAddEditHeadSidebarActive = ref(false)
    const isAddEditLessonSidebarActive = ref(false)
    const isAddEditTestSidebarActive = ref(false)
    const isAddEditFinalTestSidebarActive = ref(false)
    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data)
            .forEach(key => {
              buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
            })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    }
    return {
      isAddEditModuleSidebarActive,
      isAddEditHeadSidebarActive,
      isAddEditLessonSidebarActive,
      isAddEditTestSidebarActive,
      isAddEditFinalTestSidebarActive,
      buildFormData,
    }
  },
  created() {
    localize('ru', ru)
  },
  methods: {
    deleteTest(testIndex) {
      this.questions.splice(testIndex, 1)
      this.update()
    },
    addTest(index) {
      this.test = index
      this.isAddEditFinalTestSidebarActive = true

    },
    deleteTest(testIndex) {
      this.tests.splice(testIndex, 1)
    },
    addOption(testIndex) {
      this.tests[testIndex].options.push({
        text: '',
        correct: false,
      })
    },
    deleteOption(testIndex, optionIndex) {
      this.tests[testIndex].options.splice(optionIndex, 1)
    },
    showAddEditModuleSidebar(module) {
      this.isAddEditModuleSidebarActive = true
      this.selectedModule = module
    },
    deleteModule(index) {
      this.modules.splice(index, 1)
    },
    showAddEditHeadSidebar(module, head) {
      if (head > -1) {
        const typeChild = this.modules[module].child[head].type
        if (typeChild === 'head') {
          this.isAddEditHeadSidebarActive = true
          this.selectedModule = module
          this.selectedHead = head
        } else if (typeChild === 'lesson') {
          this.isAddEditLessonSidebarActive = true
          this.selectedModule = module
          this.selectedLesson = head
        } else if (typeChild === 'test') {
          this.isAddEditTestSidebarActive = true
          this.selectedModule = module
          this.selectedTest = head
        }
      } else {
        this.isAddEditHeadSidebarActive = true
        this.selectedModule = module
        this.selectedHead = head
      }
    },
    deleteHead(module, head) {
      this.modules[module].child.splice(head, 1)
    },
    callLessonOrTest(module, head, lesson, type) {
      if (type === 'lesson') {
        this.showAddEditLessonSidebar(module, head, lesson)
      } else if (type === 'test') {
        this.showAddEditTestSidebar(module, head, lesson)
      }
    },
    showAddEditLessonSidebar(module, head, lesson) {
      this.isAddEditLessonSidebarActive = true
      this.selectedModule = module
      this.selectedHead = head
      this.selectedLesson = lesson
    },
    deleteLesson(module, head, lesson) {
      this.modules[module].child[head].child.splice(lesson, 1)
    },
    showAddEditTestSidebar(module, head, test) {
      this.isAddEditTestSidebarActive = true
      this.selectedModule = module
      this.selectedHead = head
      this.selectedTest = test
    },
    formSubmitted() {
      const data = {
        user_id: 1,
        title: this.title,
        image: this.photo,
        type: this.type,
        about: this.about,
        status: 1,
        modules: this.modules,
        finalTests: this.tests,
      }
      const formData = new FormData()

      this.buildFormData(formData, data)
      this.$http.post('https://edu.startupchoikhona.tj/backend/api/course/create-all', formData)
          .then(response => {
            this.$router.replace('/courses')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Успешо',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: response.data.message,
                    },
                  })
                })
                .catch()
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ошибка',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: error.data.message,
              },
            })
          })
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.courseDetailsRules.validate()
            .then(success => {
              if (success) {
                resolve(true)
              } else {
                reject()
              }
            })
      })
    },
    validationFormModuleDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.moduleDetailsRules.validate()
            .then(success => {
              if (success) {
                resolve(true)
              } else {
                reject()
              }
            })
      })
    },
    validationFormFinalTest() {
      return new Promise((resolve, reject) => {
        this.$refs.finalTestRules.validate()
            .then(success => {
              if (success) {
                resolve(true)
              } else {
                reject()
              }
            })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';

#about .ql-editor {
  min-height: 300px;
}
</style>
