<template>
  <b-sidebar
      id="add-edit-test-sidebar"
      :visible="isAddEditTestSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      width="1200px"
      shadow
      backdrop
      no-header
      right
      :no-close-on-backdrop="true"
      @hidden="resetForm"
      @shown="onShow"
      @change="sideBarChange"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ test > -1 ? 'Изменить тест' : 'Добавить новый тест' }}
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2 add-test-form"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <!-- Наименование тест -->
          <validation-provider
              #default="{ errors }"
              name="названия тест"
              rules="required"
          >
            <b-form-group
                label="Названия тест"
                label-for="title"
            >
              <b-form-input
                  id="title"
                  v-model="title"
                  autofocus
                  :state="errors.length > 0 ? false:null"
                  trim
                  placeholder=""
                  autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-card v-for="(question, index) in tests" :key="index">
            <b-form-group
                :label="`Вопрос ${index+1}`"
                :label-for="`question ${index+1}`"
            >
              <quill-editor
                  :id="`question ${index+1}`"
                  v-model="question.text"
                  :options="editorOption"
              />
            </b-form-group>
            <hr/>
            <b-form-group
                :label="`Вариант ${oIndex+1}`"
                :label-for="`option ${oIndex+1}`"
                v-for="(option, oIndex) in question.options"
                :key="oIndex"
            >
              <div class="form-check-inline">
                <b-form-checkbox
                    v-model="option.correct"
                    class="custom-control-primary"
                >
                </b-form-checkbox>
                <quill-editor
                    :id="`option ${oIndex+1}`"
                    v-model="option.text"
                    :options="editorOption"
                />
                <feather-icon
                    class="ml-1"
                    icon="Trash2Icon"
                    size="18"
                    @click="deleteOption(index, oIndex)"
                />
              </div>
            </b-form-group>
            <b-form-group>
              <b-button
                  variant="light"
                  class="float-right"
                  @click="addOption(index)"
              >
                <span class="text-nowrap">Добавить вариант</span>
              </b-button>
            </b-form-group>
            <template #footer>
              <feather-icon
                  class="float-right"
                  icon="Trash2Icon"
                  size="18"
                  @click="deleteTest(index)"
              />
            </template>
          </b-card>
          <b-form-group>
            <b-button
                variant="light"
                class="float-right"
                @click="addTest"
            >
              <span class="text-nowrap">Добавить тест</span>
            </b-button>
          </b-form-group>


          <!-- Form Actions -->
          <div class="d-flex mt-2 add-tests-form-btn">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              Сохранить
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              Отменить
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BCard,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import {
  required, alphaNum, email, regex,
} from '@validations'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
import * as Quill from 'quill'

Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    BSidebar,
    BForm,
    BCard,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    ImageResize,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddEditTestSidebarActive',
    event: 'update:is-add-edit-test-sidebar-active',
  },
  props: {
    isAddEditTestSidebarActive: {
      type: Boolean,
      required: true,
    },
    module: {
      type: Number,
      required: true,
    },
    head: {
      type: Number,
      required: true,
    },
    test: {
      type: Number,
      required: true,
    },
    modules: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      regex,
      title: '',
      tests: [],
      editorOption: {
        theme: 'snow',
        bounds: '#scrolling-container',
        scrollingContainer: '#scrolling-container',
        placeholder: 'Описание',
        modules: {
          imageResize: {
          },
          toolbar: {
            container:  [
              [{ size: ['6px', '8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ],
          },
        },
      },
    }
  },
  methods: {
    addTest() {
      this.tests.push({
        type: 'test',
        name: 'Тест',
        options: [],
      })
    },
    deleteTest(testIndex) {
      this.tests.splice(testIndex, 1)
    },
    addOption(testIndex) {
      this.tests[testIndex].options.push({
        text: '',
        correct: false,
      })
    },
    deleteOption(testIndex, optionIndex) {
      this.tests[testIndex].options.splice(optionIndex, 1)
    },
    sideBarChange(val) {
      this.$emit('update:is-add-edit-test-sidebar-active', val)
    },
    onShow() {
      if (this.module > -1) {
        if (this.head > -1 && this.test > -1) {
          this.title = this.modules[this.module].child[this.head].child[this.test].name
          this.tests = this.modules[this.module].child[this.head].child[this.test].tests
        } else if (this.head <= -1 && this.test > -1) {
          this.title = this.modules[this.module].child[this.test].name
          this.tests = this.modules[this.module].child[this.test].tests
        }
      }
      console.log(this.tests)
    },
    onSubmit() {
      if (this.module > -1) {
        if (this.head > -1 && this.test > -1) {
          this.modules[this.module].child[this.head].child[this.test].name = this.title
          this.modules[this.module].child[this.head].child[this.test].tests = this.tests
        } else if (this.head <= -1 && this.test > -1) {
          this.modules[this.module].child[this.test].name = this.title
          this.modules[this.module].child[this.test].tests = this.tests
        } else if (this.head > -1 && this.test <= -1) {
          this.modules[this.module].child[this.head].child.push({
            name: this.title,
            tests: this.tests,
            type: 'test',
          })
        } else if (this.head <= -1 && this.test <= -1) {
          this.modules[this.module].child.push({
            name: this.title,
            tests: this.tests,
            type: 'test',
            child: []
          })
        }
      }
      this.$emit('update:is-add-edit-test-sidebar-active', false)
    },
    resetForm() {
      this.title = ''
      this.tests = []
      this.$refs.refFormObserver.reset()
    },
  },
  created() {
    localize('ru', ru)
  }
}
</script>

<style lang="scss">
#text .ql-editor {
  min-height: 300px;
}

.add-tests-form-btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  left: 0;
  background-color: #fff;
}

.add-test-form {
  padding-bottom: 6rem !important;
}
</style>
