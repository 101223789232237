var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-edit-lesson-sidebar","visible":_vm.isAddEditLessonSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","width":"1200px","shadow":"","backdrop":"","no-header":"","right":"","no-close-on-backdrop":true},on:{"hidden":_vm.resetForm,"shown":_vm.onShow,"change":_vm.sideBarChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.lesson > -1 ? 'Изменить тема' : 'Добавить новая тема')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2 add-lesson-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Наименование тема","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Наименование тема","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","autofocus":"","state":errors.length > 0 ? false:null,"trim":"","placeholder":"","autocomplete":"off"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"тип тема","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Тип","label-for":"type","label-class":"mb-1"}},[_c('b-form-radio-group',{attrs:{"id":"type","options":_vm.typeOptions,"value":"1","state":errors.length > 0 ? false:null},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.type===1)?_c('b-form-group',{attrs:{"label":"Тект","label-for":"text"}},[_c('quill-editor',{attrs:{"id":"text","options":_vm.editorOption},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1):_vm._e(),(_vm.type===2)?_c('b-form-group',{attrs:{"label":"Файл","label-for":"file"}},[_c('b-form-file',{attrs:{"browse-text":"Обзор...","accept":"application/pdf, .docx, .doc, .ppt, .pptx, .html","id":"file","placeholder":"Выберите файл или перетащите его сюда...","drop-placeholder":"Скиньте файл сюда..."},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1):_vm._e(),_c('div',{staticClass:"d-flex mt-2 add-lessons-form-btn"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Сохранить ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Отменить ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }